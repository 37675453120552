.filter-select {
    .select2-selection {
        padding-right: 13px;
        min-height: 49px;
        text-align: left;
        cursor: pointer !important;
        background: transparent !important;
        border: none !important;
        width: auto !important;
        padding-left: 0;
        font-size: 14px;

        .select2-selection__rendered {
            line-height: 49px !important;
            font-size: 14px;
            padding-left: 20px !important;
            padding-right: 24px !important;
        }

        .select2-selection__arrow {
            line-height: 49px;
            height: 26px !important;
            position: absolute !important;
            top: 11px !important;
            right: 10px !important;
            width: 20px !important;
        }

    }
}

.view-select {
    .select2-selection__arrow {
        position: absolute !important;
        top: 5px !important;
    }
}