//button
.bp-fileDate-btn{
    background-color: white !important;
    outline: none;
    border-top:#e0e0e0;
    border-bottom:#e0e0e0;
}

//dialog
.bp3-dialog {
    padding-bottom: 0;
}
//select
.bp3-popover-wrapper{
    .bp3-popover-target {
        &.bp3-popover-open {
            .bp3-icon {
                &.bp3-icon-caret-down{
                    transform: rotate(180deg);
                }
            } 
            
        }
    }
}
.bp-select{
    display: flex;
    justify-content: space-between;
    align-items: center;   
    &.bg-white
    {
        background-color: white;
        background-image: none;
    }
}
//popover
.bp3-popover {
    .bp3-popover-content{
        ul{
            &.bp3-menu{
                padding: 0;
                .bp3-menu-item{
                    padding-top: 8px;
                    padding-bottom: 8px;
                    &.bp3-active{
                        color: #000;
                        background-color: $color-primary;
                    }
                    &:hover{
                        background-color: $color-primary;
                    }
                }
            }
        }
        .bp3-input-group {
            .bp3-input {
                &:active {
                    box-shadow: none;
                    border-color: transparent;
                }
            }
        }
    }
}

//Radio Group
%transBg {
    &:checked{
        ~.bp3-control-indicator {
            background-color: transparent !important;
        }
    }
}
.bp3-control {
    input{
        &:checked{
            ~.bp3-control-indicator {
                background-color: transparent !important;
                width: 1.1em;
                height: 1.1em;
                &:before {
                    width: 1.1em;
                    height: 1.1em;
                    background-image:  radial-gradient(#000, #000 28%, transparent 35%) !important;
                }
            }     
        }
        
    }
    &:hover {
        input{
            @extend %transBg;
        }
    }
}