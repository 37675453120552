//font variables
$base-font: 16px;
$base-font-fw: 300;





$color-text-red: red;

$color-primary: #e0eef9;


