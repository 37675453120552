%mp0 {
  margin: 0;
  padding: 0;
}

%Html {
  @extend %mp0;
  height: 100%;
  font-size: $base-font;
  font-weight: $base-font-fw;
  line-height: 1.15;
}
