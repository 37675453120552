.docstatus-cell {
    @include d-flex {
        align-items: getAlign('c');
    }
}

.loader {
    display: inline-block;
    position: absolute;
    width: 80px;
    height: 80px;
    left: 48%;
    top: 40%
}

.loader div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #1e8ae4;
    animation: loader 1.2s cubic-bezier(0, .5, .5, 1) infinite
}

.loader div:nth-child(1) {
    left: 8px;
    animation-delay: -.24s
}

.loader div:nth-child(2) {
    left: 32px;
    animation-delay: -.12s
}

.loader div:nth-child(3) {
    left: 56px;
    animation-delay: 0
}

@keyframes loader {
    0% {
        top: 8px;
        height: 64px
    }

    100%,
    50% {
        top: 24px;
        height: 32px
    }
}

#loadercover {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, .7);
    z-index: 9999;
    display: show
}

