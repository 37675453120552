@font-face {
    font-family: 'MuseoSans';
    src: url("../../theme/fonts/MuseoSans_300-webfont.eot");
    src: url("../../theme/fonts/MuseoSans_300-webfont.eot?#iefix") format("embedded-opentype"), url("../../theme/fonts/MuseoSans_300-webfont.woff") format("woff"), url("../../theme/fonts/MuseoSans_300-webfont.ttf") format("truetype"), url("../../theme/fonts/MuseoSans_300-webfont.svg#MuseoSans300") format("svg");
    font-weight: 300;
    font-style: normal
}

@font-face {
    font-family: 'MuseoSans';
    src: url("../../theme/fonts/MuseoSans_300_Italic-webfont.eot");
    src: url("../../theme/fonts/MuseoSans_300_Italic-webfont.eot?#iefix") format("embedded-opentype"), url("../../theme/fonts/MuseoSans_300_Italic-webfont.woff") format("woff"), url("../../theme/fonts/MuseoSans_300_Italic-webfont.ttf") format("truetype"), url("../../theme/fonts/MuseoSans_300_Italic-webfont.svg#MuseoSans300Italic") format("svg");
    font-weight: 300;
    font-style: italic
}

@font-face {
    font-family: 'MuseoSans';
    src: url("../../theme/fonts/MuseoSans_500-webfont.eot");
    src: url("../../theme/fonts/MuseoSans_500-webfont.eot?#iefix") format("embedded-opentype"), url("../../theme/fonts/MuseoSans_500-webfont.woff") format("woff"), url("../../theme/fonts/MuseoSans_500-webfont.ttf") format("truetype"), url("../../theme/fonts/MuseoSans_500-webfont.svg#MuseoSans500") format("svg");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'MuseoSans';
    src: url("../../theme/fonts/MuseoSans_700-webfont.eot");
    src: url("../../theme/fonts/MuseoSans_700-webfont.eot?#iefix") format("embedded-opentype"), url("../../theme/fonts/MuseoSans_700-webfont.woff") format("woff"), url("../../theme/fonts/MuseoSans_700-webfont.ttf") format("truetype"), url("../../theme/fonts/MuseoSans_700-webfont.svg#MuseoSans700") format("svg");
    font-weight: 700;
    font-style: normal
}

@font-face {
    font-family: 'MuseoSans';
    src: url("../../theme/fonts/MuseoSans_700_Italic-webfont.eot");
    src: url("../../theme/fonts/MuseoSans_700_Italic-webfont.eot?#iefix") format("embedded-opentype"), url("../../theme/fonts/MuseoSans_700_Italic-webfont.woff") format("woff"), url("../../theme/fonts/MuseoSans_700_Italic-webfont.ttf") format("truetype"), url("../../theme/fonts/MuseoSans_700_Italic-webfont.svg#MuseoSans700Italic") format("svg");
    font-weight: 700;
    font-style: italic
}

@font-face {
    font-family: 'MuseoSans';
    src: url("../../theme/fonts/MuseoSans_900-webfont.eot");
    src: url("../../theme/fonts/MuseoSans_900-webfont.eot?#iefix") format("embedded-opentype"), url("../../theme/fonts/MuseoSans_900-webfont.woff") format("woff"), url("../../theme/fonts/MuseoSans_900-webfont.ttf") format("truetype"), url("../../theme/fonts/MuseoSans_900-webfont.svg#MuseoSans900") format("svg");
    font-weight: 900;
    font-style: normal
}